import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ContactForm from "../components/form/contact";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "../components/links";
import "../styles/pages/contact.scss";
// import 
gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const headerRef = useRef(null);

  useEffect(() => {
    gsap.set(headerRef.current, { y: -100 });
    gsap.fromTo(
      headerRef.current,
      {
        y: -100,
      },
      {
        y: -300,
        duration: 1,
        scrollTrigger: {
          trigger: "rc-contact",
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      }
    );
  }, []);
  return (
    <div className="rc-contact">
      <section className="rc-contact__header">
        <div className="rc-contact__header__inner">
          <h1>Let's work together.</h1>
          <Link isAnchor to="#contact-form">
            <div className="rc-contact__header__image">
              <div
                ref={headerRef}
                className="rc-contact__header__image__wrapper"
              >
                <StaticImage
                  src="../images/LosAngeles2.png"
                  alt="Los Angeles"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
            </div>
          </Link>
          <div className="rc-contact__header__text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--white)"
                d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
              />
            </svg>
            <h4>We are located in Los Angeles, California (PST)</h4>
          </div>
        </div>
      </section>
      <section id="contact-form" className="rc-contact__form">
        <div className="rc-contact__form__inner">
          <ContactForm />
        </div>
      </section>
    </div>
  );
};

export default Contact;
