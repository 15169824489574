import React, { useState } from "react";
import { fields, useFieldErrors } from "../validations";
import "./contact-form.scss";
import CTA from "../../Button";
import SuccessPage from "../../SuccessPage";
import { TextBox } from "../fields";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization: "",
    services: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setFieldErrors, touched] = useFieldErrors(fields, formData);

  const hasErrors = () => {
    for (const field in errors) {
      if (errors[field] && errors[field].length > 0) {
        return true;
      }
    }
    return false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    const field = fields.find((f) => f.id === name);
    if (field) {
      setFieldErrors(field, value, true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitted) {
      return;
    }

    fields.forEach((field) => {
      setFieldErrors(field, formData[field.id] || "", true);
    });

    if (hasErrors()) {
      console.log("Form has errors. Not submitting.");
      return;
    }
    console.log("Form submitting...");

    const apiGatewayInvokeURL =
      process.env.NODE_ENV === "production"
        ? process.env.GATSBY_CONTACT_FORM_ENDPOINT
        : process.env.CONTACT_API_GATEWAY_URL;

    try {
      const response = await fetch(apiGatewayInvokeURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.status === 200) {
        setIsSubmitted(true);
        console.log("Form submitted successfully.");
        console.log("Response data:", data);
      } else {
        console.log("Form submission failed.");
        console.log("Response data:", data);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  const submitText = isSubmitted ? "Success!" : "Submit";

  return (
    <>
      <SuccessPage isSubmitted={isSubmitted} returnButton text="Success!" />
      <form className="contact-form" onSubmit={handleSubmit}>
        <TextBox
          type="text"
          name="name"
          value={formData.name || ""}
          onChange={handleChange}
          placeholder="Name"
          errors={touched.name ? errors.name : []}
          parentErrors={errors.name}
          borderColor="var(--red)"
        />
        <TextBox
          type="email"
          name="email"
          value={formData.email || ""}
          onChange={handleChange}
          placeholder="Email"
          errors={touched.email ? errors.email : []}
          parentErrors={errors.email}
          borderColor="var(--gold)"
        />
        <TextBox
          type="text"
          name="organization"
          value={formData.organization || ""}
          onChange={handleChange}
          placeholder="Organization"
          parentErrors={errors.organization}
          borderColor="var(--green)"
        />
        <TextBox
          type="text"
          name="services"
          value={formData.services || ""}
          onChange={handleChange}
          placeholder="Service requested"
          parentErrors={errors.services}
          borderColor="var(--blue)"
        />
        <TextBox
          type="text"
          textArea={true}
          name="message"
          value={formData.message || ""}
          onChange={handleChange}
          placeholder="Message"
          errors={touched.message ? errors.message : []}
          parentErrors={errors.message}
          borderColor="var(--purple)"
        />
        <CTA
          className="contact-form__submit"
          submitted={isSubmitted}
          button={true}
          theme="hollow"
        >
          {submitText}
        </CTA>
      </form>
    </>
  );
};

export default ContactForm;
