import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";
import "./success-page.scss";
import CTA from "../Button";
import { colorizer } from "../../lib/colorizer";
import { useCheckScreenSize } from "../../lib/checkScreenSize";

const SuccessPage = ({ text, returnButton = false, isSubmitted = false }) => {
  const loadingContainerRef = useRef(null);
  const contentRef = useRef(null);
  const isMobile = useCheckScreenSize(800);

  gsap.set(loadingContainerRef.current, { y: "140%" });

  useEffect(() => {
    const tl = gsap.timeline();

    if (isSubmitted === true) {
      tl.to(loadingContainerRef.current, {
        y: "0",
        duration: 0.3,
        delay: 0,
        ease: "power4.out",
      });
    }
  }, [isSubmitted]);

  return (
    <div className="success-page" ref={loadingContainerRef}>
      <div className="success-page__image">
        {!isMobile && (
          <StaticImage
            src="../../images/fishermanStill.png"
            alt="Background Image"
            layout="fullWidth"
            quality={60}
            formats={["AUTO", "WEBP", "AVIF"]}
            loading="lazy"
            placeholder="blurred"
          />
        )}
        {isMobile && (
          <StaticImage
            src="../../images/fishermanStillTablet.png"
            alt="Background Image"
            layout="fullWidth"
            quality={60}
            formats={["AUTO", "WEBP", "AVIF"]}
            loading="lazy"
            placeholder="blurred"
          />
        )}
      </div>
      <div
        ref={contentRef}
        style={{ zIndex: 2 }}
        className="success-page__content"
      >
        <div className="success-page__content__inner">
          <h1>{colorizer(text)}</h1>
          <div className="success-page__content__text">
            <p>Thanks for getting in touch!</p>
            <p>We'll reach back out as soon as we can.</p>
          </div>
          <div className="success-page__content__button">
            {returnButton && (
              <CTA theme="hollow" to="/">
                Return home
              </CTA>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
