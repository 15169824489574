import React from "react";
import classNames from "classnames";
import "./text-box.scss";

const TextBox = ({
  name,
  value,
  onChange = {},
  placeholder,
  errors,
  type,
  textArea = false,
  borderColor="var(--white)",
  parentErrors,
}) => {

  const boxClasses = classNames("rc-text-box__box", {
    "rc-text-box__box--red": borderColor === "var(--red)",
    "rc-text-box__box--gold": borderColor === "var(--gold)",
    "rc-text-box__box--green": borderColor === "var(--green)",
    "rc-text-box__box--blue": borderColor === "var(--blue)",
    "rc-text-box__box--purple": borderColor === "var(--purple)",
  });

  return (
    <div className="rc-text-box">
      <div className={boxClasses}>
        {textArea === false && (
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        )}
        {textArea && (
          <textarea
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        )}
      </div>
      <ul className="rc-text-box__errors">
        {errors &&
          errors.map((error) => (
            <li key={error} className="rc-text-box__errors__error">
              {error}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TextBox;
